import 'src/styles/pages/imbatibles.scss'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Suspense } from 'react'
import { CarouselBanner } from 'src/components/sections/CarouselBanner'
import Newsletter from 'src/components/sections/Newsletter'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import ProductShelf from 'src/components/sections/ProductShelf'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import { ITEMS_PER_SECTION } from 'src/constants'
import { useCmsLpImbatiblesQuery } from 'src/components/hooks/useCmsLpImbatiblesQuery'
import { useSession } from 'src/sdk/session'

interface Props {
  location: {
    pathname: string
  }
}

function Page ( { location }: Props){
  const { locale } = useSession()
  const { site, bannerImages, collectionShelfOne, collectionShelfTwo, collectionShelfThree, collectionShelfFour  } = useCmsLpImbatiblesQuery()
  const title: string = site?.title ?? ''

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={site?.titleTemplate ?? ''}
        description={site?.description ?? ''}
        canonical={location.pathname}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.description ?? '',
        }}
      />

      <CarouselBanner data-testid='Carousel' banners={bannerImages} />

      <Suspense fallback={<ProductShelfSkeleton loading />}>
        <ProductShelf
          first={ITEMS_PER_SECTION}
          selectedFacets={[
            {
              key: 'productClusterIds',
              value: collectionShelfOne?.CollectionID ?? '197',
            },
          ]}
          title={collectionShelfOne?.Title ?? '¡Hasta 16% OFF'}
          link={collectionShelfOne?.Link ?? '/'}
        />
      </Suspense>

      <Suspense fallback={<ProductShelfSkeleton loading />}>
        <ProductShelf
          first={ITEMS_PER_SECTION}
          selectedFacets={[
            {
              key: 'productClusterIds',
              value: collectionShelfTwo?.CollectionID ?? '197',
            },
          ]}
          title={collectionShelfTwo?.Title ?? '¡Hasta 38%'}
          link={collectionShelfTwo?.Link ?? '/'}
        />
      </Suspense>
      <Suspense fallback={<ProductShelfSkeleton loading />}>
        <ProductShelf
          first={ITEMS_PER_SECTION}
          selectedFacets={[
            {
              key: 'productClusterIds',
              value: collectionShelfThree?.CollectionID ?? '197',
            },
          ]}
          title={collectionShelfThree?.Title ?? '¡Hasta 30%'}
          link={collectionShelfThree?.Link ?? '/'}
        />
      </Suspense>
      <Suspense fallback={<ProductShelfSkeleton loading />}>
        <ProductShelf
          first={ITEMS_PER_SECTION}
          selectedFacets={[
            {
              key: 'productClusterIds',
              value: collectionShelfFour?.CollectionID ?? '197',
            },
          ]}
          title={collectionShelfFour?.Title ?? '¡Hasta 50%'}
          link={collectionShelfFour?.Link ?? '/'}
        />
      </Suspense>
      <div className='imbatibles__newsletter'>
        <Newsletter
          title="Recibí promociones y novedades"
          subtitle="Ingresá tu correo electrónico"
        />
      </div>
      <StockAvailable icon />
    </>
  )
}

export default Page
